<template>
    <div>
        <ts-page-title
            :title="$t('pensionContributionWage.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('pensionContributionWage.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t('addNew')
                }}</Button>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template
                    slot-scope="{ row }"
                    slot="is_contribution_wage_on_gross"
                >
                    <span
                        v-if="row.is_contribution_wage_on_gross"
                        class="badge bg-success tw-mr-1 tw-capitalize"
                    >
                        Yes
                    </span>
                    <span v-else class="badge bg-danger tw-mr-1 tw-capitalize">
                        No
                    </span>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('pensionContributionWage.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>
<script>
import FromAction from './form.vue'
import { mapState } from 'vuex'
import { Errors } from 'form-backend-validation'

export default {
    name: 'pension-contribution-wage',
    components: {
        FromAction
    },
    data () {
        return {
            errors: new Errors(),
            loading: false,
            width: 'width: 300px',
            showForm: false
        }
    },
    computed: {
        ...mapState('payroll/pensionContributionWage', [
            'resources',
            'pagination'
        ]),

        columns () {
            return [
                {
                    title: this.$t('pensionContributionWage.wageFrom'),
                    key: 'wage_from',
                    sortable: true,
                    minWidth: 150
                },
                {
                    title: this.$t('pensionContributionWage.wageTo'),
                    key: 'wage_to',
                    sortable: true,
                    minWidth: 150
                },
                {
                    title: this.$t('pensionContributionWage.contributionWage'),
                    key: 'contribution_wage',
                    sortable: true,
                    minWidth: 150
                },
                {
                    title: this.$t(
                        'pensionContributionWage.isContributionWageOnGross'
                    ),
                    key: 'is_contribution_wage_on_gross',
                    slot: 'is_contribution_wage_on_gross',
                    sortable: true,
                    minWidth: 150,
                    align: 'center'
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    align: 'center',
                    fixed: 'right',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        addNew () {
            this.showForm = true
            this.$refs.form_action.clearInput()
        },
        clearEdit () {
            this.showForm = false
            this.$store.commit(
                'payroll/pensionContributionWage/SET_EDIT_DATA',
                {}
            )
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('payroll/pensionContributionWage/fetch', attributes)
                .catch(error => {
                    this.loading = false
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onEdit (record) {
            (this.showForm = true),
                this.$store.commit(
                    'payroll/pensionContributionWage/SET_EDIT_DATA',
                    record
                )
            this.$refs.form_action.setEditData()
        },
        async onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch(
                    "payroll/pensionContributionWage/destroy",
                    record.id
                )
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                });
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PENSION CONTRIBUTION WAGE RATE',
                desc: not.text
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
