<template>
    <div>
        <div class="demo-spin-article">
            <div class="row">
                <label class="form-label colabel required">{{
                    $t('pensionContributionWage.wageFrom')
                }}</label>
            </div>
            <div class="col-md-12 mb-3">
                <Poptip trigger="focus">
                    <Input
                        style="width: 276%"
                        type="number"
                        v-model="model.wage_from"
                        :placeholder="$t('pensionContributionWage.enterNumber')"
                        :class="{
                            'is-invalid': errors.has('wage_from')
                        }"
                    >
                    </Input>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('wage_from')"
                    >
                        {{ errors.first('wage_from') }}
                    </div>
                    <div slot="content">
                        {{
                            model.wage_from
                                ? formatNumber(model.wage_from)
                                : $t('pensionContributionWage.enterNumber')
                        }}
                    </div>
                </Poptip>
            </div>
            <div class="row">
                <label class="form-label colabel required">{{
                    $t('pensionContributionWage.wageTo')
                }}</label>
            </div>
            <div class="col-md-12 mb-3">
                <Poptip trigger="focus">
                    <Input
                        style="width: 276%"
                        type="number"
                        :placeholder="$t('pensionContributionWage.enterNumber')"
                        v-model="model.wage_to"
                        :class="{
                            'is-invalid': errors.has('wage_to')
                        }"
                    >
                    </Input>
                    <div class="invalid-feedback" v-if="errors.has('wage_to')">
                        {{ errors.first('wage_to') }}
                    </div>
                    <div slot="content">
                        {{
                            model.wage_to
                                ? formatNumber(model.wage_to)
                                : $t('pensionContributionWage.enterNumber')
                        }}
                    </div>
                </Poptip>
            </div>
            <div class="row">
                <label class="form-label colabel">{{
                    $t('pensionContributionWage.contributionWage')
                }}</label>
            </div>
            <div class="col-md-12 mb-3">
                <Poptip trigger="focus">
                    <Input
                        :placeholder="$t('pensionContributionWage.enterNumber')"
                        style="width: 276%"
                        type="number"
                        v-model="model.contribution_wage"
                        :class="{
                            'is-invalid': errors.has('contribution_wage')
                        }"
                    >
                    </Input>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('contribution_wage')"
                    >
                        {{ errors.first('contribution_wage') }}
                    </div>
                    <div slot="content">
                        {{
                            model.contribution_wage
                                ? formatNumber(model.contribution_wage)
                                : $t('pensionContributionWage.enterNumber')
                        }}
                    </div>
                </Poptip>
            </div>
            <div class="col-md-12 mb-3">
                <ts-checkbox v-model="model.is_contribution_wage_on_gross">{{
                    $t('pensionContributionWage.isContributionWageOnGross')
                }}</ts-checkbox>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t('cancel') }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t('saveAddNew') }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}
                    </ts-button>
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onUpdate"
                    >
                        {{ $t('update') }}
                    </ts-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { isEmpty } from 'lodash'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'pension-contribution-wage-form-create',
    data () {
        return {
            errors: new Errors(),
            waiting_new: false,
            waiting: false,
            loading: false,
            model: {
                wage_from: null,
                wage_to: null,
                contribution_wage: null,
                is_contribution_wage_on_gross: false
            }
        }
    },
    computed: {
        ...mapState('payroll/pensionContributionWage', ['edit_data']),
        ...mapGetters(['formatNumber']),

        isUpdate () {
            return !isEmpty(this.edit_data)
        }
    },
    methods: {
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/pensionContributionWage/store', this.model)
                .then(response => {
                    this.notice({ type: 'success', text: response.message })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('payroll/pensionContributionWage/store', this.model)
                .then(response => {
                    this.notice({ type: 'success', text: response.message })
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store.dispatch('payroll/pensionContributionWage/update', {
                id: this.edit_data.id,
                data: this.model
            })
            this.$store
                .dispatch('payroll/pensionContributionWage/update', {
                    id: this.edit_data.id,
                    data: this.model
                })
                .then(response => {
                    this.notice({ type: 'success', text: response.message })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.wage_from = this.edit_data.wage_from
                this.model.wage_to = this.edit_data.wage_to
                this.model.contribution_wage = this.edit_data.contribution_wage
                this.model.is_contribution_wage_on_gross =
                    this.edit_data.is_contribution_wage_on_gross
            }
        },
        clearInput () {
            this.errors = new Errors()
            this.model.wage_from = null
            this.model.wage_to = null
            this.model.contribution_wage = null
            this.model.is_contribution_wage_on_gross = false
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PENSION CONTRIBUTION WAGE RATE',
                desc: not.text
            })
        }
    }
}
</script>
